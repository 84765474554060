import { useEffect, useState } from 'react';
import { getRestaurantDataByHotelId } from '../../api/restaurantAPI';
import Hero from '../component/hero/hero';
import RestaurantsBarBody from '../component/restaurants-bar-body/restaurants-bar-body';

interface props {
  hotelId: any;
}

function RestaurantsBars({ hotelId }: props) {
  const [restaurantDetails, setRestaurantDetails] = useState([]);
  const [contentLoading, setContentLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //get Restaurants Bar details
  useEffect(() => {
    getRestaurantDataByHotelId(hotelId).then((data) => {
      try {
        if (data['result']) {
          if (data['result'] === 'SUCCESS') {
            setRestaurantDetails(data['data']);
            setContentLoading(true);
          } else if (data['result'] === 'FAILED') {
          } else {
          }
        } else {
        }
      } catch (error) {}
    });
  }, [hotelId]);

  return (
    <div>
      <Hero hotelId={hotelId} type="RST_H" />
      {contentLoading && (
        <RestaurantsBarBody restaurantDetails={restaurantDetails} />
      )}
    </div>
  );
}

export default RestaurantsBars;
