import { useState } from 'react';
import { BsBookmarkStar } from 'react-icons/bs';
import { Modal } from 'react-bootstrap';
import { Stepper, Step } from 'react-form-stepper';
import { addDays } from 'date-fns';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';

import BookingForm from '../forms/booking-form/booking-form';
import DoHaveDates from '../forms/Steps/do-have-dates/do-have-dates';
import TravelMonths from '../forms/Steps/travel-months/travel-months';
import DateRange from '../forms/Steps/date-range/date-range';
import NumOfTourist from '../forms/Steps/num-of-tourist/num-of-tourist';
import { BookingRequest } from '../../../models/booking';
import { submitLead } from '../../../api/other';

import {
  doHaveDatesList,
  numOfAdultsList,
  numOfKidsList,
  travelMonthList,
} from '../../../constants/constants';
import ThankYou from '../forms/Steps/thank-you/thank-you';
import ErrorPage from '../forms/Steps/error-page/error-page';

import './booking.css';

interface props {
  location: any;
  currentPhoneNumber: string;
  hotelMainData: any;
}

function Booking({ location, currentPhoneNumber, hotelMainData }: props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);

  const [showBookingModal, setShowBookingModal] = useState(false);
  const [activeStep, setActiveStep] = useState(0);

  //answer list
  const [doHaveDatesAns, setDoHaveDatesAns] = useState();
  const [dateRangesAns, setDateRangeAns] = useState({
    startDate: addDays(new Date(), 2),
    endDate: addDays(new Date(), 2),
    key: 'selection',
  });
  const [travelMonthsAns, setTravelMonthsAns] = useState<any>();
  const [touristNumberAns, setTouristNumberAns] = useState<any>({
    adultsNumber: 1,
    kidsNumber: 0,
    kidsAgeList: [],
  });
  const [name, setName] = useState<string>('');
  const [errorList, setErrorList] = useState<any>([]);

  const changeCurrentStep = (
    currentStep: number,
    nextStep: number,
    answer?: any
  ) => {
    setActiveStep(nextStep);

    if (answer !== undefined) {
      switch (currentStep) {
        case 0:
          setDoHaveDatesAns(answer);
          break;
        case 1:
          if (typeof answer == 'number') {
            setTravelMonthsAns(answer);
          } else if (typeof answer == 'object') {
            setDateRangeAns(answer);
          }
          break;
        case 2:
          setTouristNumberAns(answer);
          break;
        default:
      }
    }
  };

  const onSubmitClick = (values: BookingRequest) => {
    setErrorList([]);
    setIsLoading(true);

    const leadDetails = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phoneNumber: values.phoneNumber,
      city: values.city.label,
      doHaveDates:
        doHaveDatesAns !== undefined && doHaveDatesList[doHaveDatesAns],
      travelMonths:
        travelMonthsAns !== undefined ? travelMonthList[travelMonthsAns] : '',
      travelStartDate: moment(dateRangesAns.startDate).format('MM/DD/YYYY'),
      travelEndDate: moment(dateRangesAns.endDate).format('MM/DD/YYYY'),
      numOfAdults:
        touristNumberAns.adultsNumber !== undefined &&
        numOfAdultsList[touristNumberAns.adultsNumber],
      numOfKids:
        touristNumberAns.kidsNumber !== undefined &&
        numOfKidsList[touristNumberAns.kidsNumber],
      kidsAgeList: touristNumberAns.kidsAgeList.toString(),
      ipLocation: location.ip,
      hotelName: hotelMainData?.name,
      url: hotelMainData?.url,
      utmSource: searchParams.get('utm_source'),
      utmContent: searchParams.get('utm_content'),
    };

    setName(`${values.firstName} ${values.lastName}`);

    let departureDate = moment(dateRangesAns.startDate).format('DD/MM/YYYY');
    let arrivalDate = moment(dateRangesAns.endDate).format('DD/MM/YYYY');
    let distribution = `${touristNumberAns.adultsNumber}-${
      touristNumberAns.kidsNumber
    }-${touristNumberAns.kidsAgeList.toString()}`;

    if (departureDate === arrivalDate) {
      arrivalDate = moment(departureDate, 'DD/MM/YYYY')
        .add(1, 'days')
        .format('DD/MM/YYYY');
    }

    let hotelUrl = `https://bueno.paquetedinamico.com/home?directSubmit=true&tripType=ONLY_HOTEL&multiHotel=false&hotelDestination=Hotel::${hotelMainData?.buenoId}&departureDate=${departureDate}&arrivalDate=${arrivalDate}&distribution=${distribution}&lang=ES&displayCurrency=USD&carRental=false`;

    submitLead(leadDetails).then((data) => {
      try {
        if (data['result']) {
          if (data['result'] === 'SUCCESS') {
            setErrorList([]);
            // setActiveStep(4);
            window.location.href = hotelUrl;
          } else if (data['result'] === 'FAILED') {
            setErrorList(data['data'].errorMessage);
          } else {
            setErrorList(['Por favor, inténtelo de nuevo más tarde']);
          }
        } else {
          setErrorList(['Por favor, inténtelo de nuevo más tarde']);
        }
      } catch (error) {
        setErrorList(['Por favor, inténtelo de nuevo más tarde']);
      }
      setIsLoading(false);
    });
  };

  return (
    <>
      <div className="booking-div">
        <button
          className="booking-btn"
          onClick={() => setShowBookingModal(true)}
        >
          <BsBookmarkStar /> Reservar ahora
        </button>
      </div>
      <Modal
        size="lg"
        show={showBookingModal}
        onHide={() => setShowBookingModal(false)}
        centered
      >
        <Modal.Header className="modal-header-div" closeButton></Modal.Header>
        <Modal.Body>
          <div className="num-of-tourist center">
            {activeStep !== 4 && !isLoading && (
              <div className="row">
                <div className="col">
                  <Stepper
                    activeStep={activeStep}
                    connectorStateColors={true}
                    styleConfig={{
                      activeBgColor: '#62c5f3',
                      activeTextColor: '#ffffff',
                      completedBgColor: '#004464',
                      completedTextColor: '#ffffff',
                      inactiveBgColor: '#bdbdbd',
                      inactiveTextColor: '#ffffff',
                      size: '2em',
                      circleFontSize: '1rem',
                      labelFontSize: '0.875rem',
                      borderRadius: '50%',
                      fontWeight: '500',
                    }}
                    connectorStyleConfig={{
                      disabledColor: '#bdbdbd',
                      activeColor: '#62c5f3',
                      completedColor: '#004464',
                      size: '2px',
                      stepSize: '2em',
                      style: 'solid',
                    }}
                  >
                    <Step onClick={() => changeCurrentStep(activeStep, 0)} />
                    <Step onClick={() => changeCurrentStep(activeStep, 1)} />
                    <Step onClick={() => changeCurrentStep(activeStep, 2)} />
                    <Step onClick={() => changeCurrentStep(activeStep, 3)} />
                  </Stepper>
                </div>
              </div>
            )}
          </div>
          {activeStep === 0 && (
            <DoHaveDates
              doHaveDatesAns={doHaveDatesAns}
              changeCurrentStep={changeCurrentStep}
            />
          )}
          {activeStep === 1 && doHaveDatesAns === 0 && (
            <DateRange
              dateRangesAns={dateRangesAns}
              changeCurrentStep={changeCurrentStep}
            />
          )}
          {activeStep === 1 && doHaveDatesAns === 1 && (
            <TravelMonths
              travelMonthsAns={travelMonthsAns}
              changeCurrentStep={changeCurrentStep}
            />
          )}
          {activeStep === 2 && (
            <NumOfTourist
              touristNumberAns={touristNumberAns}
              changeCurrentStep={changeCurrentStep}
            />
          )}
          {activeStep === 3 && (
            <BookingForm
              onSubmitClick={onSubmitClick}
              errorList={errorList}
              isLoading={isLoading}
              location={location}
            />
          )}
          {activeStep === 4 && (
            <ThankYou name={name} currentPhoneNumber={currentPhoneNumber} />
          )}
          {activeStep === 5 && <ErrorPage errorList={errorList} />}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Booking;
