import { useEffect } from 'react';
import ContactUsBody from '../component/contact-us-body/contact-us-body';

interface props {
  hotelId: any;
  currentPhoneNumber: string;
  contactNumberList: any[];
}

function ContactUs({ hotelId, currentPhoneNumber, contactNumberList }: props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <ContactUsBody
        currentPhoneNumber={currentPhoneNumber}
        contactNumberList={contactNumberList}
      />
    </div>
  );
}

export default ContactUs;
