import { useState } from 'react';
import { TravelMonthAnswers } from '../../../../../constants/constants';
import StepButton from '../step-button/step-button';

import './travel-months.css';

interface props {
  travelMonthsAns: any;
  changeCurrentStep(currentStep: number, nextStep: number, answer: any): void;
}

function TravelMonths({ travelMonthsAns, changeCurrentStep }: props) {
  const currentStep = 1;
  const [answer, setAnswer] = useState(travelMonthsAns);

  const clickTravelMonths = (value: TravelMonthAnswers | any) => {
    setAnswer(value);
    changeCurrentStep(currentStep, 2, value);
  };

  return (
    <div className="do-you-have-dates center">
      <div className="row mb-4">
        <div className="col">
          <img
            className="step-img-icon"
            src="/images/step-icons/step-2-icon.svg"
            alt="step"
          />
          <h4>¿Cuando Piensa Viajar?</h4>
        </div>
      </div>
      <div className="row d-flex justify-content-center">
        <div className="col-sm-5 mb-2">
          <StepButton
            answer={answer}
            value={TravelMonthAnswers['Ene - Mar']}
            text="Ene - Mar"
            btnClicked={() =>
              clickTravelMonths(TravelMonthAnswers['Ene - Mar'])
            }
          />
        </div>
        <div className="col-sm-5 mb-2">
          <StepButton
            answer={answer}
            value={TravelMonthAnswers['Abr - Jun']}
            text="Abr - Jun"
            btnClicked={() =>
              clickTravelMonths(TravelMonthAnswers['Abr - Jun'])
            }
          />
        </div>
      </div>
      <div className="row d-flex justify-content-center">
        <div className="col-sm-5 mb-2">
          <StepButton
            answer={answer}
            value={TravelMonthAnswers['Jul - Sept']}
            text="Jul - Sept"
            btnClicked={() =>
              clickTravelMonths(TravelMonthAnswers['Jul - Sept'])
            }
          />
        </div>
        <div className="col-sm-5 mb-2">
          <StepButton
            answer={answer}
            value={TravelMonthAnswers['Oct - Dic']}
            text="Oct - Dic"
            btnClicked={() =>
              clickTravelMonths(TravelMonthAnswers['Oct - Dic'])
            }
          />
        </div>
      </div>
      <div className="row d-flex justify-content-center">
        <div className="col-sm-5 mb-2">
          <StepButton
            answer={answer}
            value={TravelMonthAnswers['No estoy seguro']}
            text="No estoy seguro"
            btnClicked={() =>
              clickTravelMonths(TravelMonthAnswers['No estoy seguro'])
            }
          />
        </div>
      </div>
    </div>
  );
}

export default TravelMonths;
