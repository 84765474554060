import SimpleImageSlider from 'react-simple-image-slider';
import MediaQuery from 'react-responsive';
import { useEffect, useState } from 'react';
import { getHeroImagesByHotelAndImageType } from '../../../api/imageAPI';

interface props {
  hotelId: any;
  type: string;
}

function Hero({ hotelId, type }: props) {
  const [heroImg, setHeroImg] = useState([]);
  const [imgLoading, setImgLoading] = useState(false);

  //get image list
  useEffect(() => {
    getHeroImagesByHotelAndImageType(hotelId, type).then((data) => {
      try {
        if (data['result']) {
          if (data['result'] === 'SUCCESS') {
            setHeroImg(data['data']);
            setImgLoading(true);
          } else if (data['result'] === 'FAILED') {
          } else {
          }
        } else {
        }
      } catch (error) {}
    });
  }, [hotelId, type]);

  return (
    <>
      {imgLoading && (
        <div>
          <MediaQuery minWidth={1224}>
            <SimpleImageSlider
              width={'100%'}
              height={'70vh'}
              images={heroImg}
              showBullets={true}
              showNavs={true}
              slideDuration={1}
              autoPlay={true}
            />
          </MediaQuery>
          <MediaQuery maxWidth={1224}>
            <SimpleImageSlider
              width={'100%'}
              height={'40vh'}
              images={heroImg}
              showBullets={true}
              showNavs={true}
              slideDuration={1}
              autoPlay={true}
            />
          </MediaQuery>
        </div>
      )}
    </>
  );
}

export default Hero;
