import './loading-screen.css';

interface props {
  hotelImg: string;
}

function LoadingScreen({ hotelImg }: props) {
  return (
    <div className="loading-screen" id={'loading-screen-identifier'}>
      <div className="img-div">
        <img
          src={hotelImg ? hotelImg : '/images/logo/logo.png'}
          alt="img"
        ></img>
      </div>
      <div className="loading-dots">
        <div className="dot" />
        <div className="dot" />
        <div className="dot" />
        <div className="dot" />
        <div className="dot" />
      </div>
    </div>
  );
}

export default LoadingScreen;
