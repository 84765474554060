import axios from 'axios';

export const getCityList = async (word: string) => {
  try {
    const token = 'TOKEN';
    let formData = new FormData();

    formData.append('q', word);
    formData.append('token', token);

    const { data } = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_HOST}/city/filter.php`,
      data: formData,
      headers: {
        Accept: 'application/json',
        'Content-Type': `multipart/form-data;`,
      },
    });

    return data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};

export const submitLead = async ({
  firstName,
  lastName,
  email,
  phoneNumber,
  city,
  doHaveDates,
  travelMonths,
  travelStartDate,
  travelEndDate,
  numOfAdults,
  numOfKids,
  kidsAgeList,
  ipLocation,
  hotelName,
  url,
  utmSource,
  utmContent,
}: any) => {
  try {
    const token = 'TOKEN';
    let formData = new FormData();

    formData.append('firstName', firstName);
    formData.append('lastName', lastName);
    formData.append('email', email);
    formData.append('phoneNumber', phoneNumber);
    formData.append('city', city);
    formData.append('doHaveDates', doHaveDates);
    formData.append('travelMonths', travelMonths);
    formData.append('travelStartDate', travelStartDate);
    formData.append('travelEndDate', travelEndDate);
    formData.append('numOfAdults', numOfAdults);
    formData.append('numOfKids', numOfKids);
    formData.append('kidsAgeList', kidsAgeList);
    formData.append('ipLocation', ipLocation);
    formData.append('sourceTitle', hotelName);
    formData.append('sourceId', '37');
    formData.append('sourceUrl', url);
    formData.append('token', token);
    formData.append('utmSource', utmSource);
    formData.append('utmContent', utmContent);

    const { data } = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_HOST}/bitrix/create_lead.php`,
      data: formData,
      headers: {
        Accept: 'application/json',
        'Content-Type': `multipart/form-data;`,
      },
    });

    return data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};

export const getIpDetails = async () => {
  try {
    const { data } = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_IPIFY_URL}`,
      data: [],
      headers: {
        Accept: 'application/json',
        'Content-Type': `multipart/form-data;`,
      },
    });

    return data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};

export const getLocationDetails = async (ip: string) => {
  try {
    const { data } = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_WEATHER_API_URL}`,
      params: { q: ip },
      headers: {
        'X-RapidAPI-Host': 'weatherapi-com.p.rapidapi.com',
        'X-RapidAPI-Key': `${process.env.REACT_APP_RAPID_API_KEY}`,
      },
    });

    return data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};

export const getBranchList = async () => {
  try {
    const { data } = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_HOST}/branch/read.php`,
      headers: {
        Accept: 'application/json',
      },
    });

    return data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};
