import { useState } from 'react';
import { DoHaveDatesAnswers } from '../../../../../constants/constants';
import StepButton from '../step-button/step-button';

import './do-have-dates.css';

interface props {
  doHaveDatesAns: any;
  changeCurrentStep(currentStep: number, nextStep: number, answer: any): void;
}

function DoHaveDates({ doHaveDatesAns, changeCurrentStep }: props) {
  const currentStep = 0;
  const [answer, setAnswer] = useState(doHaveDatesAns);

  const clickDoHaveDates = (value: DoHaveDatesAnswers | any) => {
    setAnswer(value);

    changeCurrentStep(currentStep, 1, value);
  };

  return (
    <div className="do-you-have-dates center">
      <div className="row mb-4">
        <div className="col">
          <img
            className="step-img-icon"
            src="/images/step-icons/do-you-have-dates.svg"
            alt="step"
          />
          <h4>¿Tienes fechas específicas para viajar?</h4>
        </div>
      </div>
      <div className="row mb-2  d-flex justify-content-center">
        <div className="col-sm-6">
          <StepButton
            answer={answer}
            value={DoHaveDatesAnswers.YES}
            text="Sí tengo"
            btnClicked={() => clickDoHaveDates(DoHaveDatesAnswers.YES)}
          />
          {/* <button
            type="button"
            className={`step-button ${
              answer === DoHaveDatesAnswers.YES && 'step-button-active'
            }`}
            onClick={() => clickDoHaveDates(DoHaveDatesAnswers.YES)}
          >
            Sí tengo
          </button> */}
        </div>
      </div>
      <div className="row mb-2 d-flex justify-content-center">
        <div className="col-sm-6">
          <StepButton
            answer={answer}
            value={DoHaveDatesAnswers.NO}
            text="No, no tengo"
            btnClicked={() => clickDoHaveDates(DoHaveDatesAnswers.NO)}
          />
        </div>
      </div>
    </div>
  );
}

export default DoHaveDates;
