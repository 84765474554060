import { Col, Container, Row } from 'react-bootstrap';
import ReactHtmlParser from 'react-html-parser';

import './about-hotel.css';

interface props {
  hotelMainData: any;

  about: string;
}

function AboutHotel({ hotelMainData, about }: props) {
  return (
    <div className="hotel-about-us-div pt-5 pb-5">
      <Container>
        <Row className="justify-content-md-center text-center mb-5">
          <Col lg="8">
            <h3>{hotelMainData?.name}</h3>
            <h4 className="about-us-head">
              {hotelMainData?.name} - {hotelMainData?.subNameDescription}
            </h4>
          </Col>
        </Row>
        {ReactHtmlParser(about)}
      </Container>
    </div>
  );
}

export default AboutHotel;
