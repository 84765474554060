import React, { Suspense, useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, Outlet } from 'react-router-dom';

import { getBranchList, getIpDetails, getLocationDetails } from './api/other';
import { getPhoneNumber } from './meta/other';

import HotelIndex from './hotel/page/hotel-index';
import HotelFooter from './hotel/component/hotel-footer/hotel-footer';
import LoadingScreen from './components/loading-screen/loading-screen';
import { getHotelList, hotelReadById } from './api/hotelAPI';
import HotelHome from './hotel/page/hotel-home';
import AccommodationsPage from './hotel/page/accommodations-page';
import RestaurantsBars from './hotel/page/restaurants-bars-page';
import AllInclusivePage from './hotel/page/all-inclusive-page';
import WeddingPage from './hotel/page/weddings-page';
import GalleryPage from './hotel/page/gallery-page';
import ContactUs from './hotel/page/contact-us-page';

// const AccommodationsPage = React.lazy(
//   () => import('./hotel/page/accommodations-page')
// );
// const AllInclusivePage = React.lazy(
//   () => import('./hotel/page/all-inclusive-page')
// );
// const ContactUs = React.lazy(() => import('./hotel/page/contact-us-page'));
// const GalleryPage = React.lazy(() => import('./hotel/page/gallery-page'));
// const HotelHome = React.lazy(() => import('./hotel/page/hotel-home'));
// const RestaurantsBars = React.lazy(
//   () => import('./hotel/page/restaurants-bars-page')
// );
// const WeddingPage = React.lazy(() => import('./hotel/page/weddings-page'));

function App() {
  const hotelId = process.env.REACT_APP_HOTEL_ID
    ? process.env.REACT_APP_HOTEL_ID
    : 1;
  const [location, setLocation] = useState<any>({
    ip: '',
    countryCode: '',
    country: '',
    city: '',
  });

  const [hotelList, setHotelList] = useState<any[]>([]);

  const [hotelMainData, setHotelMainData] = useState<any>({});

  const [contactNumberList, setContactNumberList] = useState<any>([]);

  const [currentPhoneNumber, setCurrentPhoneNumber] = useState<string>('');

  //get IP address and Gio Location
  useEffect(() => {
    getIpDetails().then((data) => {
      try {
        if (data.ip) {
          getLocationDetails(data.ip).then((data) => {
            try {
              if (data.country_code) {
                setLocation({
                  ip: data?.ip,
                  countryCode: data?.country_code,
                  country: data?.country,
                  city: data?.city,
                });
                setCurrentPhoneNumber(
                  getPhoneNumber(contactNumberList, data?.country_code)
                );
              } else {
                setCurrentPhoneNumber(getPhoneNumber(contactNumberList, 'US'));
              }
            } catch (error) {
              setCurrentPhoneNumber(getPhoneNumber(contactNumberList, 'US'));
            }
          });
        } else {
          setCurrentPhoneNumber(getPhoneNumber(contactNumberList, 'US'));
        }
      } catch (error) {
        setCurrentPhoneNumber(getPhoneNumber(contactNumberList, 'US'));
      }
    });
  }, []);

  //get hotel list
  useEffect(() => {
    getHotelList().then((data) => {
      try {
        if (data['result']) {
          if (data['result'] === 'SUCCESS') {
            setHotelList(data['data']);
          } else if (data['result'] === 'FAILED') {
          } else {
          }
        } else {
        }
      } catch (error) {}
    });
  }, []);

  //get branch list
  useEffect(() => {
    getBranchList().then((data) => {
      try {
        if (data['result']) {
          if (data['result'] === 'SUCCESS') {
            setContactNumberList(data['data']);
          } else if (data['result'] === 'FAILED') {
          } else {
          }
        } else {
        }
      } catch (error) {}
    });
  }, []);

  //set phone number
  useEffect(() => {
    if (location?.countryCode) {
      setCurrentPhoneNumber(
        getPhoneNumber(contactNumberList, location?.countryCode)
      );
    } else {
      setCurrentPhoneNumber(getPhoneNumber(contactNumberList, 'US'));
    }
  }, [location, contactNumberList]);

  //get hotel list
  useEffect(() => {
    hotelReadById(hotelId).then((data) => {
      try {
        if (data['result']) {
          if (data['result'] === 'SUCCESS') {
            setHotelMainData(data['data']);
          } else if (data['result'] === 'FAILED') {
          } else {
          }
        } else {
        }
      } catch (error) {}
    });
  }, []);

  return (
    <BrowserRouter>
      <Suspense fallback={<LoadingScreen hotelImg={hotelMainData?.logo} />}>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <HotelIndex
                  hotelMainData={hotelMainData}
                  location={location}
                  currentPhoneNumber={currentPhoneNumber}
                />
                <Outlet />
                <HotelFooter
                  hotelMainData={hotelMainData}
                  hotelList={hotelList}
                  currentPhoneNumber={currentPhoneNumber}
                />
              </>
            }
          >
            <Route
              index
              element={
                <HotelHome hotelId={hotelId} hotelMainData={hotelMainData} />
              }
            ></Route>
            <Route
              path="accommodations"
              element={<AccommodationsPage hotelId={hotelId} />}
            ></Route>
            <Route
              path="restaurants-bars"
              element={<RestaurantsBars hotelId={hotelId} />}
            ></Route>
            <Route
              path="all-inclusive"
              element={<AllInclusivePage hotelId={hotelId} />}
            ></Route>
            <Route
              path="wedding"
              element={
                <WeddingPage
                  hotelId={hotelId}
                  hotelName={hotelMainData?.name}
                />
              }
            ></Route>
            <Route
              path="gallery"
              element={
                <GalleryPage
                  hotelId={hotelId}
                  hotelName={hotelMainData?.name}
                />
              }
            ></Route>
            <Route
              path="contact-us"
              element={
                <ContactUs
                  hotelId={hotelId}
                  currentPhoneNumber={currentPhoneNumber}
                  contactNumberList={contactNumberList}
                />
              }
            ></Route>
          </Route>
          <Route path="*" element={<p>404 PAGE</p>} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
