import { useEffect, useState } from 'react';
import { getAllInclusiveDataByHotelId } from '../../api/allInclusiveAPI';
import AllInclusiveBody from '../component/all-inclusive-body/all-inclusive-body';
import Hero from '../component/hero/hero';

interface props {
  hotelId: any;
}

function AllInclusivePage({ hotelId }: props) {
  const [allInclusiveDetails, setAllInclusiveDetails] = useState([]);
  const [contentLoading, setContentLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //get all inclusive Bar details
  useEffect(() => {
    getAllInclusiveDataByHotelId(hotelId).then((data) => {
      try {
        if (data['result']) {
          if (data['result'] === 'SUCCESS') {
            setAllInclusiveDetails(data['data']);
            setContentLoading(true);
          } else if (data['result'] === 'FAILED') {
          } else {
          }
        } else {
        }
      } catch (error) {}
    });
  }, [hotelId]);

  return (
    <div>
      <Hero hotelId={hotelId} type="AID_H" />
      {contentLoading && (
        <AllInclusiveBody allInclusiveDetails={allInclusiveDetails} />
      )}
    </div>
  );
}

export default AllInclusivePage;
