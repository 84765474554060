import ReactHtmlParser from 'react-html-parser';

import './other.css';

interface props {
  hotelName: string;
  other: string;
}

function Other({ hotelName, other }: props) {
  return <>{ReactHtmlParser(other)}</>;
}

export default Other;
