import React, { useEffect } from 'react';
import Booking from '../component/booking/booking';
import NavBar from '../component/nav-bar/nav-bar';
import TopHeader from '../component/top-header/top-header';

// const Booking = React.lazy(() => import('../component/booking/booking'));

interface props {
  hotelMainData: any;
  location: any;
  currentPhoneNumber: string;
}

function HotelIndex({ hotelMainData, location, currentPhoneNumber }: props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <TopHeader
        hotelMainData={hotelMainData}
        currentPhoneNumber={currentPhoneNumber}
      />
      <NavBar />
      <Booking
        hotelMainData={hotelMainData}
        location={location}
        currentPhoneNumber={currentPhoneNumber}
      />
    </div>
  );
}

export default HotelIndex;
