import { useEffect } from 'react';
import GalleryBody from '../component/gallery-body/gallery-body';

interface props {
  hotelId: any;
  hotelName: string;
}

function GalleryPage({ hotelId, hotelName }: props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <GalleryBody hotelId={hotelId} hotelName={hotelName} />
    </div>
  );
}

export default GalleryPage;
