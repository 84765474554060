export const getPhoneNumber = (phoneNumberList: any, country: any) => {
  let returnValue = '';

  if (phoneNumberList && phoneNumberList.length > 0) {
    for (let i = 0; i < phoneNumberList.length; i++) {
      if (phoneNumberList[i]?.countryCode === country) {
        returnValue = phoneNumberList[i].number;
        break;
      }
    }

    if (returnValue === '') {
      returnValue = phoneNumberList[0].number;
    }
  }

  return returnValue;
};
