import { Col, Container, Row } from 'react-bootstrap';
import ImageGallery from 'react-image-gallery';

import './wedding-body.css';
import 'react-image-gallery/styles/css/image-gallery.css';
import { useEffect, useState } from 'react';
import { getGalleryImagesByHotelAndImageType } from '../../../api/imageAPI';

interface props {
  hotelId: any;
  hotelName: string;
}

function WeddingBody({ hotelId, hotelName }: props) {
  const [weddingImg, setWeddingImg] = useState([]);
  const [imgLoading, setImgLoading] = useState(false);

  //get image list
  useEffect(() => {
    getGalleryImagesByHotelAndImageType(hotelId, 'WEDDING').then((data) => {
      try {
        if (data['result']) {
          if (data['result'] === 'SUCCESS') {
            setWeddingImg(data['data']);
            setImgLoading(true);
          } else if (data['result'] === 'FAILED') {
          } else {
          }
        } else {
        }
      } catch (error) {}
    });
  }, [hotelId]);

  return (
    <div className="accommodations-body-div pt-5 pb-5">
      <Container>
        <Row className="justify-content-md-center text-center mb-5">
          <Col lg="8">
            <h1>{hotelName}</h1>
            <h4>Celebra tu Boda en {hotelName}</h4>
            <p>
              Disfruta del extraordinario placer de casarte en el paraíso;{' '}
              {hotelName} es el lugar ideal para tu boda y luna de miel.
            </p>
          </Col>
        </Row>
        {imgLoading && (
          <Row className="justify-content-md-center text-center mb-5">
            <Col lg="8">
              <ImageGallery items={weddingImg} />
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
}

export default WeddingBody;
