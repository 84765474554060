interface stepProps {
  answer: any;
  value: any;
  text: any;
  btnClicked(value: any): void;
}

function StepButton({ answer, value, text, btnClicked }: stepProps) {
  return (
    <button
      type="button"
      className={`step-button ${answer === value && 'step-button-active'}`}
      onClick={() => btnClicked(value)}
    >
      {text}
    </button>
  );
}

export default StepButton;
