import { Col, Container, Row } from 'react-bootstrap';

import './contact-us-body.css';

interface props {
  currentPhoneNumber: string;
  contactNumberList: any[];
}

function ContactUsBody({ currentPhoneNumber, contactNumberList }: props) {
  return (
    <div className="accommodations-body-div pt-5 pb-5">
      <Container>
        <Row className="justify-content-md-center text-center mb-5">
          <Col lg="8">
            <h1>Contáctenos</h1>
          </Col>
        </Row>
        <Row className="justify-content-md-center text-left mb-5">
          <Col lg="8">
            <p>
              Vacation Coupon ofrece vacaciones todo incluido con increíbles
              descuentos en una amplia selección de resorts en el Caribe. Deje
              que nuestro personal profesional lo ayude a encontrar las
              vacaciones familiares perfectas, la escapada de fin de semana, las
              vacaciones de primavera o esa luna de miel especial.
            </p>
            <h3>¿Por qué elegir Vacation Coupon?</h3>
            <ul className="contact-us-list">
              <li>
                <i className="fa fa-check-circle"></i> Con más de 1,4 millones
                de hoteles en 200 países y regiones, hemos construido una
                extensa red de hoteles para ofrecer a nuestros clientes una
                fantástica opción de alojamiento.
              </li>
              <li>
                <i className="fa fa-check-circle"></i> Nuestra amplia red de
                vuelos tiene más de 2 millones de rutas de vuelos individuales
                que conectan más de 5000 ciudades de todo el mundo.
              </li>
              <li>
                <i className="fa fa-check-circle"></i> Cuando combina esto con
                nuestro servicio de atención al cliente en inglés las 24 horas,
                los 7 días de la semana y varios otros productos de viaje, puede
                confiar en que nos ocuparemos de su próximo viaje.
              </li>
            </ul>
            <p>
              Satisfacer a nuestros Huéspedes es nuestro principal objetivo. Por
              ello nos gustaría facilitar el proceso de reservas con nuestro
              servicio de reservas telefónicas. Si necesita ayuda, contáctenos
              por teléfono o ENVÍENOS UN CORREO ELECTRÓNICO
            </p>
            <h4>
              Llámenos Nacionalmente al{' '}
              <a href={`tel:${currentPhoneNumber}`}>{currentPhoneNumber}</a>
            </h4>
          </Col>
        </Row>
        <Row className="justify-content-md-center text-center mb-5">
          <Col lg="8">
            <h1>Alrededor de Global</h1>
            <p>
              Contamos con un buen equipo alrededor del mundo que ayuda a
              mejorar nuestro camino
            </p>
          </Col>
        </Row>
        <Row className="justify-content-md-center text-center mb-5">
          {contactNumberList &&
            contactNumberList.map((branch, i) => {
              return (
                <Col lg="3" key={i}>
                  <div className="branch-details-div">
                    <img
                      className="country-img"
                      src={`https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/4x3/${branch?.countryCode.toLowerCase()}.svg`}
                      alt="img"
                    ></img>

                    <h4 className="">{branch.country}</h4>
                    <a
                      href={`tel:${branch.number}`}
                      className="branch-tel text-size-16"
                    >
                      {branch.number}
                    </a>
                    <p className="branch-address text-size-16"></p>
                  </div>
                </Col>
              );
            })}
        </Row>
      </Container>
    </div>
  );
}

export default ContactUsBody;
