import { useEffect } from 'react';
import WeddingBody from '../component/wedding-body/wedding-body';

interface props {
  hotelId: any;
  hotelName: string;
}

function WeddingPage({ hotelId, hotelName }: props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <WeddingBody hotelId={hotelId} hotelName={hotelName} />
    </div>
  );
}

export default WeddingPage;
