import { useEffect } from 'react';

import AboutHotel from '../component/about-hotel/about-hotel';
import Hero from '../component/hero/hero';
import HotelVideo from '../component/hotel-video/hotel-video';
import Other from '../component/other/other';

interface props {
  hotelMainData: any;
  hotelId: any;
}

function HotelHome({ hotelId, hotelMainData }: props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Hero hotelId={hotelId} type="HOME_H" />
      <AboutHotel hotelMainData={hotelMainData} about={hotelMainData?.about} />
      {hotelMainData?.video && <HotelVideo hotelVideo={hotelMainData?.video} />}
      <Other hotelName={hotelMainData?.name} other={hotelMainData?.other} />
    </div>
  );
}

export default HotelHome;
