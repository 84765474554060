import axios from 'axios';

export const getHeroImagesByHotelAndImageType = async (
  hotelId: any,
  type: string
) => {
  try {
    let formData = new FormData();

    formData.append('hotelId', hotelId);
    formData.append('type', type);

    const { data } = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_HOST}/image/readByHotelAndType.php`,
      data: formData,
      headers: {
        Accept: 'application/json',
      },
    });

    return data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};

export const getGalleryImagesByHotelAndImageType = async (
  hotelId: any,
  type: string
) => {
  try {
    let formData = new FormData();

    formData.append('hotelId', hotelId);
    formData.append('type', type);

    const { data } = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_HOST}/image/readGalleryImgByHotelAndType.php`,
      data: formData,
      headers: {
        Accept: 'application/json',
      },
    });

    return data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};
