import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import './hotel-footer.css';

interface props {
  hotelMainData: any;
  hotelList: any[];
  currentPhoneNumber: string;
}

function HotelFooter({ hotelMainData, hotelList, currentPhoneNumber }: props) {
  return (
    <div className="hotel-footer-div pt-5 pb-1">
      <Container>
        <Row className="justify-content-md-center text-center mb-5">
          <Col lg="8">
            <h5>
              {hotelMainData?.name}, {hotelMainData?.subNameDescription} -
              Llámanos sin cargo al <br></br>
              <a href={`tel:${currentPhoneNumber}`}>{currentPhoneNumber}</a>
            </h5>
          </Col>
        </Row>
        <Row className="justify-content-md-center text-center">
          <Col lg="10">
            <ul className="footer-ul">
              {hotelList &&
                hotelList.map((hotel, i) => {
                  return (
                    <li key={i}>
                      <a href={hotel.link}>{hotel.name}</a>
                    </li>
                  );
                })}
            </ul>
          </Col>
        </Row>
        <Row className="justify-content-md-center text-center">
          <Col lg="8">
            <ul className="footer-ul">
              <li>
                <Link to="/accommodations">Alojamientos</Link>
              </li>
              <li>
                <Link to="/restaurants-bars">Restaurantes y Bares</Link>
              </li>
              <li>
                <Link to="/all-inclusive">Todo incluido</Link>
              </li>
              <li>
                <Link to="/contact-us">Contacta con nosotras</Link>
              </li>
            </ul>
          </Col>
        </Row>
        <Row className="justify-content-md-center text-center">
          <Col lg="8">
            <ul className="footer-ul-copy footer-ul">
              <li>
                <a href="http://www.vacationcoupon.com">
                  © vacationcoupon.com {new Date().getFullYear()} Todos los
                  derechos reservados Política de privacidad
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default HotelFooter;
