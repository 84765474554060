import { Col, Container, Row } from 'react-bootstrap';

import './accommodations-body.css';

interface props {
  rooms: any[];
}

function AccommodationsBody({ rooms }: props) {
  return (
    <div className="accommodations-body-div pt-5 pb-5">
      <Container>
        <Row className="justify-content-md-center text-center mb-5">
          <Col lg="8">
            <h1>HABITACIONES</h1>
          </Col>
        </Row>
        {rooms.map((room, i) => {
          return (
            <Row
              className="justify-content-md-center room-detail-list mb-2"
              key={i}
            >
              <Col sm={4} className="text-left p-1">
                <img className="room-img" src={room.url} alt=""></img>
              </Col>
              <Col sm={8} className="text-left p-2">
                <h4 className="mb-2">{room.name}</h4>
                <ul className="accommodations-list">
                  {room.details.map((detail: any, j: any) => {
                    return (
                      <li key={j}>
                        <i className="fa fa-check-circle"></i> {detail}
                      </li>
                    );
                  })}
                </ul>
              </Col>
            </Row>
          );
        })}
      </Container>
    </div>
  );
}

export default AccommodationsBody;
