import { useEffect, useState } from 'react';

import { getRoomsByHotelId } from '../../api/roomAPI';
import AccommodationsBody from '../component/accommodations-body/accommodations-body';
import Hero from '../component/hero/hero';

interface props {
  hotelId: any;
}

function AccommodationsPage({ hotelId }: props) {
  const [accommodationDetails, setAccommodationDetails] = useState([]);
  const [contentLoading, setContentLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //get room list
  useEffect(() => {
    getRoomsByHotelId(hotelId).then((data) => {
      try {
        if (data['result']) {
          if (data['result'] === 'SUCCESS') {
            setAccommodationDetails(data['data']);
            setContentLoading(true);
          } else if (data['result'] === 'FAILED') {
          } else {
          }
        } else {
        }
      } catch (error) {}
    });
  }, [hotelId]);

  return (
    <div>
      <Hero hotelId={hotelId} type="ACMD_H" />
      {contentLoading && <AccommodationsBody rooms={accommodationDetails} />}
    </div>
  );
}

export default AccommodationsPage;
