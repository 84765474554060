import { Container, Navbar } from 'react-bootstrap';
import MediaQuery from 'react-responsive';
import { Link } from 'react-router-dom';

import './top-header.css';

interface props {
  hotelMainData: any;
  currentPhoneNumber: string;
}

function TopHeader({ hotelMainData, currentPhoneNumber }: props) {
  return (
    <>
      <Navbar bg="white">
        <Container>
          <MediaQuery minWidth={1224}>
            <Navbar.Brand to="/" as={Link}>
              <img
                src={
                  hotelMainData?.logo
                    ? hotelMainData?.logo
                    : '/images/logo/logo.png'
                }
                height="80"
                className="d-inline-block align-top"
                alt="hotel logo"
              />
            </Navbar.Brand>
            <Navbar.Collapse className="justify-content-end">
              <div className="address-div">
                <h5>{hotelMainData?.name}</h5>
                <p>{hotelMainData?.subName}</p>
                <p>{hotelMainData?.subNameDescription}</p>
                <a href={`tel:${currentPhoneNumber}`}>{currentPhoneNumber}</a>
              </div>
            </Navbar.Collapse>
          </MediaQuery>
          <MediaQuery maxWidth={1224}>
            <Navbar.Brand to="/" as={Link} style={{ margin: 'auto' }}>
              <img
                src={
                  hotelMainData?.logo
                    ? hotelMainData?.logo
                    : '/images/logo/logo.png'
                }
                width="100%"
                className="d-inline-block align-top"
                alt="hotel logo"
              />
            </Navbar.Brand>
          </MediaQuery>
        </Container>
      </Navbar>
    </>
  );
}

export default TopHeader;
