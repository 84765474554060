import './error-page.css';

interface props {
  errorList: any;
}

function ErrorPage({ errorList }: props) {
  return (
    <div className="thank-you center">
      <div className="row mb-4">
        <div className="col">
          <img
            className="step-img-icon"
            src="/images/step-icons/green-tick.png"
            alt="step"
          />
          <h5>Something went wrong please try again later!</h5>
        </div>
      </div>
      <div className="container">
        <div className="row mt-4 mb-5 d-flex justify-content-center">
          <div className="col-8">error</div>
        </div>
      </div>
    </div>
  );
}

export default ErrorPage;
