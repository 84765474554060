import { useEffect, useState, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Container, Nav, Navbar } from 'react-bootstrap';
import MediaQuery from 'react-responsive';

import './nav-bar.css';

function NavBar() {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isFixed, setIsFixed] = useState<boolean>(false);
  const [pathQuery, setPathQuery] = useState('');
  const [open, setOpen] = useState(false);

  const location = useLocation();

  useEffect(() => {
    if (location) {
      let path = location.pathname.split('/');
      setPathQuery(path[1]);
    }

    window.addEventListener('scroll', navBarPositionCheck);
    return () => {
      window.removeEventListener('scroll', navBarPositionCheck);
    };
  }, [location]);

  const navBarPositionCheck = () => {
    let elemPosition;
    if (inputRef.current) {
      elemPosition = inputRef.current.getBoundingClientRect();

      if (elemPosition.y > 0) {
        setIsFixed(false);
      } else {
        setIsFixed(true);
      }
    }
  };

  return (
    <>
      <MediaQuery minWidth={993}>
        <div ref={inputRef}>
          <Navbar
            className={isFixed ? 'fixed-top' : ''}
            collapseOnSelect
            expand="lg"
            bg="dark"
            variant="dark"
          >
            <Container>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="m-auto">
                  {/* <NavDropdown title="Resorts" id="basic-nav-dropdown">
                <NavDropdown.Item href="1/">Hotel Riu Cancun</NavDropdown.Item>
                <NavDropdown.Item href="2/">Hotel Riu Cancun</NavDropdown.Item>
                <NavDropdown.Item href="3/">Hotel Riu Cancun</NavDropdown.Item>
              </NavDropdown> */}
                  <Nav.Link
                    className={
                      pathQuery === 'accommodations' ? 'nav-active' : ''
                    }
                    to="accommodations"
                    style={{ margin: '0 13px' }}
                    as={Link}
                  >
                    ALOJAMIENTOS
                  </Nav.Link>
                  <Nav.Link
                    className={
                      pathQuery === 'restaurants-bars' ? 'nav-active' : ''
                    }
                    to="restaurants-bars"
                    style={{ margin: '0 13px' }}
                    as={Link}
                  >
                    RESTAURANTES Y BARES
                  </Nav.Link>
                  <Nav.Link
                    className={
                      pathQuery === 'all-inclusive' ? 'nav-active' : ''
                    }
                    to="all-inclusive"
                    style={{ margin: '0 13px' }}
                    as={Link}
                  >
                    DETALLES TODO INCLUIDO
                  </Nav.Link>
                  <Nav.Link
                    className={pathQuery === 'wedding' ? 'nav-active' : ''}
                    to="wedding"
                    style={{ margin: '0 13px' }}
                    as={Link}
                  >
                    BODAS
                  </Nav.Link>
                  <Nav.Link
                    className={pathQuery === 'gallery' ? 'nav-active' : ''}
                    to="gallery"
                    style={{ margin: '0 13px' }}
                    as={Link}
                  >
                    GALERÍA
                  </Nav.Link>
                  <Nav.Link
                    className={pathQuery === 'contact-us' ? 'nav-active' : ''}
                    to="contact-us"
                    style={{ margin: '0 13px' }}
                    as={Link}
                  >
                    CONTÁCTENOS
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
      </MediaQuery>
      <MediaQuery maxWidth={992}>
        <div ref={inputRef}>
          <Navbar
            className={isFixed ? 'fixed-top' : ''}
            collapseOnSelect
            expand="lg"
            bg="dark"
            variant="dark"
          >
            <Container>
              <Navbar.Toggle
                aria-controls="responsive-navbar-nav"
                onClick={() => setOpen(!open)}
              />
              <Navbar.Collapse id="responsive-navbar-nav" in={open}>
                <Nav className="m-auto" onClick={() => setOpen(!open)}>
                  {/* <NavDropdown title="Resorts" id="basic-nav-dropdown">
                <NavDropdown.Item href="1/">Hotel Riu Cancun</NavDropdown.Item>
                <NavDropdown.Item href="2/">Hotel Riu Cancun</NavDropdown.Item>
                <NavDropdown.Item href="3/">Hotel Riu Cancun</NavDropdown.Item>
              </NavDropdown> */}
                  <Nav.Link
                    className={
                      pathQuery === 'accommodations' ? 'nav-active' : ''
                    }
                    to="accommodations"
                    style={{ margin: '0 13px' }}
                    as={Link}
                  >
                    ALOJAMIENTOS
                  </Nav.Link>
                  <Nav.Link
                    className={
                      pathQuery === 'restaurants-bars' ? 'nav-active' : ''
                    }
                    to="restaurants-bars"
                    style={{ margin: '0 13px' }}
                    as={Link}
                  >
                    RESTAURANTES Y BARES
                  </Nav.Link>
                  <Nav.Link
                    className={
                      pathQuery === 'all-inclusive' ? 'nav-active' : ''
                    }
                    to="all-inclusive"
                    style={{ margin: '0 13px' }}
                    as={Link}
                  >
                    DETALLES TODO INCLUIDO
                  </Nav.Link>
                  <Nav.Link
                    className={pathQuery === 'wedding' ? 'nav-active' : ''}
                    to="wedding"
                    style={{ margin: '0 13px' }}
                    as={Link}
                  >
                    BODAS
                  </Nav.Link>
                  <Nav.Link
                    className={pathQuery === 'gallery' ? 'nav-active' : ''}
                    to="gallery"
                    style={{ margin: '0 13px' }}
                    as={Link}
                  >
                    GALERÍA
                  </Nav.Link>
                  <Nav.Link
                    className={pathQuery === 'contact-us' ? 'nav-active' : ''}
                    to="contact-us"
                    style={{ margin: '0 13px' }}
                    as={Link}
                  >
                    CONTÁCTENOS
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
      </MediaQuery>
    </>
  );
}

export default NavBar;
