import { useField, useFormikContext } from 'formik';
import PhoneInput from 'react-phone-number-input';

import 'react-phone-number-input/style.css';

const PhoneNumberInput = ({ ...props }: any) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);

  const handleChange = (value: any) => {
    setFieldValue(field.name, value);
  };

  return (
    <PhoneInput
      international
      defaultCountry={props?.countryCode ? props?.countryCode : 'US'}
      onChange={(value) => {
        handleChange(value);
      }}
      disabled={props.disabled}
      className="phone-number"
    />
  );
};

export default PhoneNumberInput;
