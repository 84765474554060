import { Col, Container, Row } from 'react-bootstrap';

import './restaurants-bar-body.css';

interface props {
  restaurantDetails: any[];
}

function RestaurantsBarBody({ restaurantDetails }: props) {
  return (
    <div className="restaurants-bar-body-div pt-5 pb-5">
      <Container>
        <Row className="justify-content-md-center text-center mb-5">
          <Col lg="8">
            <h1>Restaurantes y Bares</h1>
          </Col>
        </Row>
        <Row className="justify-content-md-center text-left mb-5">
          <Col lg="10">
            <ul className="default-list">
              {restaurantDetails.map((value, i) => {
                return (
                  <li key={i}>
                    <i className="fa fa-check-circle"></i> {value}
                  </li>
                );
              })}
            </ul>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default RestaurantsBarBody;
