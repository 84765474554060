import { useState } from 'react';
import Async from 'react-select/async';
import { getCityList } from '../../../api/other';

// import { getProductList } from "../../api/productReviewAPI";

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};

const formatGroupLabel = (data: any) => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
);

const groupBadgeStyles: any = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
};

interface props {
  selectedOption: any;
  changeCurrentValue(value: any): void;
}

function CityList({ selectedOption, changeCurrentValue }: props) {
  const [selectedValue, setSelectedValue] = useState(selectedOption);

  const handleChange = (value: any) => {
    if (value !== null) {
      setSelectedValue(value);
      changeCurrentValue(value);
    }
  };

  const loadOptions = (input: any, callback: any) => {
    getCityList(input).then((data: any) => {
      try {
        if (data['result']) {
          if (data['result'] === 'SUCCESS') {
            callback([{ label: 'Player List', options: data['data'] }]);
          } else if (data['result'] === 'FAILED') {
            callback([]);
          } else {
            callback([]);
          }
        } else {
          callback([]);
        }
      } catch (error) {
        callback([]);
      }
    });
  };

  return (
    <Async
      className="step-input-box"
      loadOptions={loadOptions}
      value={selectedValue}
      placeholder="Enter your city"
      formatGroupLabel={formatGroupLabel}
      onChange={(value) => {
        handleChange(value);
      }}
      cacheOptions={true}
      // grouped
    />
  );
}

export default CityList;
