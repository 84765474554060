import axios from 'axios';

export const getHotelList = async () => {
  try {
    const { data } = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_HOST}/hotel/read.php`,
      headers: {
        Accept: 'application/json',
      },
    });

    return data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};

export const hotelReadById = async (hotelId: any) => {
  try {
    let formData = new FormData();

    formData.append('hotelId', hotelId);

    const { data } = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_HOST}/hotel/readById.php`,
      data: formData,
      headers: {
        Accept: 'application/json',
      },
    });

    return data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};
