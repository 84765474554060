import { Col, Container, Row } from 'react-bootstrap';

import './hotel-video.css';

interface props {
  hotelVideo: string;
}

function HotelVideo({ hotelVideo }: props) {
  return (
    <div className="hotel-about-us-div pb-5">
      <Container>
        <Row className="justify-content-md-center text-center mb-5">
          <Col lg="8" className="video-responsive">
            <iframe
              src={hotelVideo}
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            ></iframe>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default HotelVideo;
